/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:04bcb35f-a388-4a0d-b6fc-9b036d884c41",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ZpJld7qHJ",
    "aws_user_pools_web_client_id": "27mjosrq864oquiond758j8v94",
    "oauth": {
        "domain": "devinls-ease-two-dev.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 12,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "frontend-20230307140520-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2oz4bip8xm9ec.cloudfront.net",
    "aws_user_files_s3_bucket": "devinls-ct-bluedevils-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
